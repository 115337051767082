import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout';
import YoutubeEmbed from "../components/YoutubeEmbed";
// import 'react-responsive-modal/styles.css';
// import { Modal } from 'react-responsive-modal';
import Seo from '../components/seo';

export const query = graphql`
    query($id: ID!) {
        wpcontent {
            vptWebcastWithDave(id: $id) {
                title
                uri
                excerpt
                content
                webinardate
                youtubeId
                seo {
                    metaDesc
                    canonical
                    title
                    opengraphTitle
                    opengraphDescription
                    opengraphType
                    twitterTitle
                    twitterDescription
                }
            }
        }
    }
`

const WebinarTemplate = ({ data }) => {
    const webinar = data.wpcontent.vptWebcastWithDave
    const metaDesc = data.wpcontent.vptWebcastWithDave.seo.metaDesc
    const pageTitle = data.wpcontent.vptWebcastWithDave.seo.title

    return (
        <Layout className="webinar-detail">
            <Seo title={pageTitle}
            description={metaDesc}
            />
            <section className="bg-purple-100  mx-auto py-10 sm:py-20 md:py-20 relative mb-20">
                <div className="container md:flex md:flex-col justify-center items-center lg:flex-row lg:justify-between max-w-6xl text-center lg:text-left px-6">
                   <div className = " w-full justify-center lg:justify-start">
                       <div className="box-label1 hover:bg-purple-800 cursor-auto"><a href="/webinars/vantage-point-webcast-with-dave-ulrich/" className="text-gray-500">Vantage Point - In Coversation with Dave Ulrich</a></div>
                        <h1 className = "section-title-v1 mb-5">{webinar.title}</h1>
                        <p className="uppercase">Streamed on {webinar.webinardate}</p>
                   </div>
                </div>
            </section>
            <section className="container max-w-6xl pb-20 px-6">
                <div className="md:flex md:items-center">
                    <div className="md:w-1/2">
                        <h2 class="md:mb-10 text-2xl font-semibold text-gray-900 sm:text-4xl">A Few key takeaways</h2>
                        <ul className="form-bullets list-inside orangebullets md:mr-5" dangerouslySetInnerHTML={{ __html: webinar.excerpt }} />
                    </div>
                    <div className="md:w-1/2">
                        <YoutubeEmbed embedId={webinar.youtubeId} />
                    </div>
                </div>
            </section>
            <section className="container max-w-6xl px-6 md:pt-10">
                <div dangerouslySetInnerHTML={{ __html: webinar.content }} />
            </section>

            <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className=" p-10 md:p-14 relative z-9 overflow-hidden" style={{"background": "#414171"}}>
                    <div  className="bottom-cta-content text-center text-white">
                        <div className="section-title-white">Find Out How Vantage Circle Works</div>
                        <div className="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a className="vc-ghost-btn-cta-banner" href="/request-demo/">Schedule a Free Demo</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section>
            
        </Layout>
    )
}

export default WebinarTemplate



